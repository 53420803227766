import React from "react";
import PropTypes from "prop-types";

const Bodytext = props => {
  const { html, theme } = props;

  return (
    <React.Fragment>
      <div className="bodytext" dangerouslySetInnerHTML={{ __html: html }} />

      {/* --- STYLES --- */}
      {/*language=SCSS*/}
      <style jsx>{`
        .bodytext {
          margin-top: 60px;
          animation-name: bodytextEntry;
          animation-duration: ${theme.time.duration.long};

          :global(h1),
          :global(h2),
          :global(h3),
          :global(h4),
          :global(h5),
          :global(h6) {
            font-family: ${theme.font.reidweb.article.header};
            margin-bottom: 0.5rem;
            margin-top: 0;
            line-height: 1.2;
            color: ${theme.colour.article.headers};
            font-weight: 600;
          }

          :global(h1) {
            line-height: ${theme.font.lineHeight.s};
            font-size: ${theme.font.size.xxxl};
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }

          :global(h2) {
            line-height: ${theme.font.lineHeight.s};
            font-size: ${theme.font.size.xxl};
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }

          :global(h3) {
            font-size: ${theme.font.size.l};
          }

          :global(h4) {
            font-size: ${theme.font.size.ml};
          }

          :global(h5) {
            font-size: ${theme.font.size.s};
          }

          :global(h6) {
            font-size: ${theme.font.size.xs};
          }

          :global(p) {
            font-size: ${theme.font.size.body};
            font-family: ${theme.font.reidweb.article.body};
            line-height: ${theme.font.lineHeight.xxl};
            color: ${theme.colour.article.text};
            font-weight: 400;
            margin: 30px 0;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }

          :global(table) {
            font-size: ${theme.font.size.s};
            font-family: ${theme.font.reidweb.article.body};
            line-height: ${theme.font.lineHeight.xxl};
            color: ${theme.colour.article.text};
            border-spacing: 0;
          }

          :global(td) {
            border: 1px solid #dbdbdb;
            padding: 0.5rem;
            text-align: left;
          }

          :global(ul) {
            padding: 0 0 0 1.5em;
            font-size: ${theme.font.size.s};
            font-family: ${theme.font.reidweb.article.body};
            line-height: ${theme.font.lineHeight.xxl};
            color: ${theme.colour.article.text};
            font-weight: 400;
            margin: 30px 0;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
          }
          :global(li) {
            margin: 0.7em 0;
            line-height: 1.5;
          }
          :global(a) {
            border-bottom: 1px solid ${theme.colour.grey.lightest};
            text-decoration: none;
            color: ${theme.colour.article.text};
            transition: color 0.15s ease-in, background 0.15s ease-in, border-color 0.15s ease-in;
          }
          :global(a.gatsby-resp-image-link) {
            border: 0;
            display: block;
            margin: 2.5em 0;
            border-radius: ${theme.size.radius.default};
            overflow: hidden;
            border: 1px solid ${theme.line.color};
          }
          :global(code.language-text) {
            margin: 0 2px;
            padding: 0.2em 0.4em;
            line-height: ${theme.font.lineHeight.xxl};
            font-size: 85%;
            font-family: ${theme.font.reidweb.article.code};
            color: ${theme.colour.article.text};
            background-color: rgba(27, 31, 35, 0.05);
            border-radius: 3px;
          }

          :global(.gatsby-highlight) {
            margin: 2.5em 0;
          }
        }

        @keyframes bodytextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Bodytext.propTypes = {
  html: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Bodytext;
